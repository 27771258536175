import React from "react";
import { ElfsightWidget } from "react-elfsight-widget";
import "./instagram.css";

const Instagram = () => {
  return (
    <div>
      <ElfsightWidget widgetId="35c07855-14fe-400a-a2db-ae88e46ebed8" lazy />
    </div>
  );
};

export default Instagram;
