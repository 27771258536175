import React, { useState, useEffect, useRef } from "react";
import { projects } from "./config";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProjectImgItem = ({ el, slide, setSlide }) => {
  const imgRef = useRef();

  const slideImg = (count) => {
    let srcCurrent = imgRef.current.attributes.src.value;

    let f = projects
      .map((category) => category.imgListSrc)
      .flat()
      .find((product) => product.src == srcCurrent);

    if (+imgRef.current.attributes.id.value === f.id) {
      count += 1;
    }
  };

  useEffect(() => {
    if (imgRef.current) {
      let timerId = null;
      imgRef.current.addEventListener("mouseenter", () => {
        let count = slide;
        slideImg(count);
        timerId = setInterval(function () {
          let srcCurrent = imgRef.current.attributes.src.value;

          let f = projects
            .map((category) => category.imgListSrc)
            .flat()
            .find((product) => product.src == srcCurrent);

          if (imgRef.current.attributes.id.value === f.id) {
            count += 1;
            setSlide(count);
          }
        }, 1000);
      });

      imgRef.current.addEventListener("mouseleave", () => {
        clearInterval(timerId);
      });
      imgRef.current.addEventListener("click", () => {
        clearInterval(timerId);
      });
    }
  }, [imgRef]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <img
        ref={imgRef}
        src={el.src}
        id={el.id}
        alt={el.src}
        className="projects__img"
        loading="lazy"
      />
    </div>
  );
};

export default ProjectImgItem;
