import React from "react";
import { NavLink } from "react-router-dom";

const Link = ({ idx, open, link, text, imgSrc }) => {
  return (
    <div className="nav-item-wrap">
      <NavLink to={link}>{text}</NavLink>

      <img
        src={imgSrc}
        className="img-nav"
        alt={`Image representing a link for ${text}`}
      />
    </div>
  );
};

export default Link;
