import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <span className="footer-logo">
        <a href="/">
          <h1>WEDLIFER</h1>
          <span>studio</span>
        </a>
      </span>
    </footer>
  );
};

export default Footer;
