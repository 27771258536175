import React, { useRef, useState, useEffect } from "react";
import imgHero1 from "../../img/reviews/rev-img-1.jpg";
import imgHero2 from "../../img/reviews/rev-img-2.jpg";
import imgHero3 from "../../img/reviews/rev-img-3.jpg";
import imgHero4 from "../../img/reviews/rev-img-4.jpg";
import imgHero5 from "../../img/reviews/rev-img-5.jpg";
import imgHero6 from "../../img/reviews/rev-img-6.jpg";
import imgHero7 from "../../img/reviews/rev-img-7.JPG";
import LoaderLight from "../../components/loaderLight/LoaderLight";
import stars from "../../img/reviews/rev-stars.svg";

import Nav from "../../components/nav/Nav";
import Footer from "../../components/footer/Footer";
import FooterImgList from "../../components/footerImgList/FooterImgList";
import "./reviews.css";
import { reviews } from "./config";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Transition from "../../components/transition/Transition";
import PolicyPopup from "../../components/policyPopup/PolicyPopup";
gsap.registerPlugin(ScrollTrigger);

const Reviews = ({ popup }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navLogoPosition = {
    left: "30px",
    transform: "translateX(0)",
  };

  const textRef = useRef(null);
  const containerRef = useRef(null);

  // loadImages start

  const imageUrls = [
    imgHero1,
    imgHero2,
    imgHero3,
    imgHero4,
    imgHero5,
    imgHero6,
    imgHero7,
  ];

  const loadImages = (imageUrls, onProgress, onComplete) => {
    const totalImages = imageUrls.length;
    let loadedCount = 0;

    const handleImageLoad = () => {
      // console.log("loadedCount1", loadedCount);
      loadedCount++;
      // console.log("loadedCount2", loadedCount);
      const currentProgress = ((loadedCount / totalImages) * 100).toFixed(0);
      onProgress(currentProgress);
      if (loadedCount === totalImages) {
        onComplete();
      }
    };

    const handleImageError = () => {
      console.error("Error loading an image.");
      loadedCount++;
      const currentProgress = ((loadedCount / totalImages) * 100).toFixed(0);
      onProgress(currentProgress);
      if (loadedCount === totalImages) {
        onComplete();
      }
    };

    const imageElements = imageUrls.map((url) => {
      const img = new Image();

      // Добавляем параметр для обхода кэша
      img.src = `${url}?timestamp=${new Date().getTime()}`;
      // console.log("img", img.src);

      img.onload = handleImageLoad;
      img.onerror = handleImageError;

      return img;
    });

    return () => {
      imageElements.forEach((img) => {
        img.onload = null;
        img.onerror = null;
      });
    };
  };

  // loadImages finished

  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false); // Флаг завершения
  const hasStarted = useRef(false);

  useEffect(() => {
    if (imageUrls.length === 0 || hasStarted.current) return;

    hasStarted.current = true; // Устанавливаем флаг, чтобы загрузка началась только один раз

    const cleanup = loadImages(
      imageUrls,
      (updatedProgress) => setProgress(Number(updatedProgress)),
      () => {
        console.log("All images loaded.");
        setIsLoading(false);
      }
    );
    // console.log("imageUrls", imageUrls);
    // console.log("progress", progress);

    return cleanup;
  }, []);

  useEffect(() => {
    if (progress === 100 && !completed) {
      setCompleted(true); // Устанавливаем флаг завершения
      // alert("ok"); // Вызываем alert только при достижении 100%
      setIsLoading(false);
    }
  }, [progress, completed]);

  useGSAP(() => {
    gsap.from(".reviews-hero-col img", {
      y: gsap.utils.random(100, 50),

      ease: "power3.out",
      duration: 2,
      stagger: 0.02,
      delay: 0.1,
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".reviews-section-title",
        scrub: 1,
      },
    });

    tl.from(".reviews-section-title", {
      y: -50,
      opacity: 0,
      duration: 2,
      ease: [0.6, -0.05, 0.01, 0.99],
    });
  });

  const reviewTl = gsap.timeline();

  return (
    <>
      <div className="reviews">
        {/* <p>Loading progress: {progress}%</p>
        <p>
          Loaded{" "}
          {Math.min((progress / 100) * imageUrls.length, imageUrls.length)} of{" "}
          {imageUrls.length} images.
        </p> */}
        {popup && <PolicyPopup />}
        <Transition timeline={reviewTl} />
        {isLoading && <LoaderLight />}
        <Nav position={navLogoPosition} />
        <div className="reviews-hero">
          <div className="reviews-hero-col">
            <div className="reviews-hero-img-1 ">
              <img src={imgHero1} alt="" />
            </div>
            <div className="reviews-hero-img-2">
              <img src={imgHero2} alt="" />
            </div>
            <div className="reviews-hero-img-3">
              <img src={imgHero3} alt="" />
            </div>
          </div>
          <div className="reviews-hero-col">
            <div className="reviews-hero-img-4">
              <img src={imgHero4} alt="" />
            </div>
            <div className="reviews-hero-img-5">
              <img src={imgHero5} alt="" />
            </div>
            <div className="reviews-hero-img-6">
              <img src={imgHero6} alt="" />
            </div>
            <div className="reviews-hero-img-7">
              <img src={imgHero7} alt="" />
            </div>
          </div>
        </div>
        <div className="reviews-content">
          <div className="reviews-section" ref={containerRef}>
            <h2 className="reviews-section-title" ref={textRef}>
              Reviews
            </h2>
            <div className="reviews-list">
              <div className="reviews-item-row">
                <div className="reviews-content-title reviews-content-title-big">
                  <span>S</span>
                  <span>W</span>
                  <span>E</span>
                  <span>I</span>
                  <span>V</span>
                  <span>E</span>
                  <span>R</span>
                </div>
                {reviews.map((item) => (
                  <div
                    className={`reviews-item ${
                      item.wFull
                        ? `w-full reviews-item-${item.id}`
                        : `reviews-item-${item.id}`
                    }`}
                    key={item.id}
                  >
                    {item.imgPos === "left" && (
                      <div>
                        <img
                          className="reviews-user-img"
                          src={item.imgUser}
                          alt={item.name}
                        />
                      </div>
                    )}
                    <div
                      className={` ${
                        item.imgPos === "right"
                          ? `reviews-item-text reviews-item-text-right`
                          : `reviews-item-text`
                      }`}
                    >
                      <span className="reviews-date">{item.dateEvent}</span>
                      <span className="reviews-item-name">{item.name}</span>
                      <div>
                        <img
                          className="reviews-stars-img"
                          src={stars}
                          alt="stars"
                        />
                      </div>
                      <p>{item.text}</p>
                    </div>
                    {item.imgPos == "right" && (
                      <div>
                        <img
                          className="reviews-user-img"
                          src={item.imgUser}
                          alt={item.name}
                        />
                      </div>
                    )}
                  </div>
                ))}

                <div className="reviews-content-title reviews-content-title-sm">
                  <span>S</span>
                  <span>W</span>
                  <span>E</span>
                  <span>I</span>
                  <span>V</span>
                  <span>E</span>
                  <span>R</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterImgList />
      </div>
      <Footer />
    </>
  );
};

export default Reviews;
