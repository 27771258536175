import React, { ref, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import { EffectFade } from "swiper/components/effect-fade/effect-fade.js";
import ProjectDetailsHero from "../../pages/projects/ProjectDetailsHero";

import { motion, useScroll } from "framer-motion";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./swiper.css";

const SwiperMob = ({
  imgListSrc,
  location,
  title,
  imgPrev,
  sizeMob,
  fullWidth,
  toggleFullWidth,
}) => {
  let newImgListSrc = imgListSrc.slice(1, -1);
  let [curSlideIdx, setCurSlideIdx] = useState(1);
  let lastImgSrc = imgListSrc.slice(-1)[0].src;
  if (curSlideIdx.toString().length < 2) curSlideIdx = "0" + curSlideIdx;

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        onSlideChange={(i) => {
          setCurSlideIdx(i.activeIndex + 1);
        }}
      >
        <SwiperSlide>
          <motion.div>
            <ProjectDetailsHero
              location={location}
              title={title}
              imgPrev={imgPrev}
              sizeMob={sizeMob}
            />
          </motion.div>
        </SwiperSlide>
        {newImgListSrc.map((img, i) => (
          <SwiperSlide key={img.id}>
            <motion.div
              transition={{ duration: 0.5 }}
              style={{
                backgroundImage: `url(..${img.src})`,
                height: "100vh",
                width: "100%",
                backgroundSize: fullWidth ? "cover" : "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></motion.div>
          </SwiperSlide>
        ))}
        <motion.span
          className="slider-count"
          initial={{ opacity: 0, translateY: -100 }}
          animate={{ opacity: 1, translateY: 0 }}
        >
          {curSlideIdx}
        </motion.span>
        {curSlideIdx !== "01" && (
          <span className="touch-icon-mob" onClick={toggleFullWidth}>
            {fullWidth ? "FULL FRAME" : "CLOSE UP"}
          </span>
        )}

        <SwiperSlide>
          <motion.div
            transition={{ duration: 0.5 }}
            style={{
              backgroundImage: `url(..${lastImgSrc})`,
              height: "100vh",
              width: "100%",
              backgroundSize: fullWidth ? "cover" : "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <span className="slider-last-img">Next Project</span>
          </motion.div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default SwiperMob;
