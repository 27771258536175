import React, { useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import img from "../../img/form/form-attention.svg";
import emailjs from "@emailjs/browser";
import "./form.css";
import DatePicker from "../datePicker/DatePickerField";
import Spinner from "../spinner/Spinner";
import Popup from "../popup/Popup";

const titleVariant = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 2,
      ease: [0.6, -0.05, 0.01, 0.99],
      delay: 0.2,
    },
  },
};

function validateTextField(value) {
  if (!value) {
    return "Required";
  } else if (value.length < 1) {
    return "Minimum 1 characters";
  }
}

function validateGuestsField(value) {
  if (!value) {
    return "Required";
  } else if (value.length < 1) {
    return "Minimum 1 characters";
  }
}

function validateEmailField(value) {
  if (!value) {
    return "Required";
  } else if (
    !/^([a-zA-Z0-9_\-\.]+){2,30}@([a-zA-Z0-9-]+.[a-zA-Z0-9-]+)$/.test(value)
  ) {
    return "Invalid email address";
  }
}

const ContactForm = () => {
  const form = useRef();
  const [popup, setPopup] = useState(false);

  return (
    <div className="form-wrapper">
      <motion.h2
        variants={titleVariant}
        initial="initial"
        animate="animate"
        className="italic"
      >
        LEAVE A MESSAGE FOR US TO FOCUS ON YOU
      </motion.h2>
      <Formik
        initialValues={{
          name: "",
          partnersName: "",
          numberGuests: "",
          email: "",
          live: "",
          eventLocation: "",
          instagramLink: "",
          date: "",
          message: "",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.date) {
            errors.date = "Required";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          try {
            emailjs
              .sendForm(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                form.current,
                {
                  publicKey: process.env.REACT_APP_PUBLIC_KEY,
                }
              )
              .then((res) => {
                console.log("SUCCESS!", res.text);
                setSubmitting(false);
                setPopup(true);
                setTimeout(() => {
                  setPopup(false);
                }, 3000);
                resetForm();
              });
          } catch (error) {
            console.log("FAILED...", error.text);
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="form" ref={form}>
            {popup && (
              <Popup
                msg={"The data has been sent, we will contact you shortly:)"}
              />
            )}
            {isSubmitting && <Spinner />}
            <div className="input-row">
              <div
                className={`${
                  errors.name && touched.name
                    ? "input-wrap error"
                    : "input-wrap"
                }`}
              >
                <Field
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder="Name"
                  validate={validateTextField}
                />
                <span className="error-label">
                  <img src={img} alt="form attention" />

                  {errors.name}
                </span>
              </div>
              <div
                className={`${
                  errors.partnersName && touched.partnersName
                    ? "input-wrap error"
                    : "input-wrap"
                }`}
              >
                <Field
                  type="text"
                  name="partnersName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.partnersName}
                  placeholder="Partner’s Name"
                  validate={validateTextField}
                />
                <span className="error-label">
                  {" "}
                  <img src={img} alt="form attention" />
                  {errors.partnersName}
                </span>
              </div>
            </div>
            <div className="input-row">
              <div
                className={`${
                  errors.numberGuests && touched.numberGuests
                    ? "input-wrap error"
                    : "input-wrap"
                }`}
              >
                <Field
                  type="text"
                  name="numberGuests"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.numberGuests}
                  placeholder="Number of guests"
                  validate={validateGuestsField}
                />
                <span className="error-label">
                  {" "}
                  <img src={img} alt="form attention" />
                  {errors.numberGuests}
                </span>
              </div>
              <div
                className={`${
                  errors.email && touched.email
                    ? "input-wrap error"
                    : "input-wrap"
                }`}
              >
                <Field
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder="Email Address"
                  validate={validateEmailField}
                />
                <span className="error-label">
                  {" "}
                  <img src={img} alt="form attention" />
                  {errors.email}
                </span>
              </div>
            </div>
            <div className="input-row">
              <div
                className={`${
                  errors.live && touched.live
                    ? "input-wrap error"
                    : "input-wrap"
                }`}
              >
                <Field
                  type="text"
                  name="live"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.live}
                  placeholder="Where Do you live?"
                  validate={validateTextField}
                />
                <span className="error-label">
                  {" "}
                  <img src={img} alt="form attention" />
                  {errors.live}
                </span>
              </div>
              <div
                className={`${
                  errors.eventLocation && touched.eventLocation
                    ? "input-wrap error"
                    : "input-wrap"
                }`}
              >
                <Field
                  type="text"
                  name="eventLocation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.eventLocation}
                  placeholder="Event Location"
                  validate={validateTextField}
                />
                <span className="error-label">
                  {" "}
                  <img src={img} alt="form attention" />
                  {errors.eventLocation}
                </span>
              </div>
            </div>
            <div className="input-row">
              <div
                className={`${
                  errors.instagramLink && touched.instagramLink
                    ? "input-wrap error"
                    : "input-wrap"
                }`}
              >
                <Field
                  type="text"
                  name="instagramLink"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.instagramLink}
                  placeholder="Instagram link"
                />
                <span className="error-label">
                  {" "}
                  <img src={img} alt="form attention" />
                  {errors.instagramLink}
                </span>
              </div>
              <div
                className={`${
                  errors.date && touched.date
                    ? "input-wrap error"
                    : "input-wrap"
                }`}
              >
                <DatePicker name="date" />

                <span className="error-label">
                  <img src={img} alt="form attention" />
                  {errors.date}
                </span>
              </div>
            </div>
            <div className="input-row">
              <div
                className={`${
                  errors.message && touched.message
                    ? "input-wrap error"
                    : "input-wrap"
                }`}
              >
                <Field
                  type="text"
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  placeholder="Tell us* more about"
                />
                <span className="error-label">
                  {" "}
                  <img src={img} alt="form attention" />
                  {errors.message}
                </span>
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-form"
            >
              <span>SEND</span>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
