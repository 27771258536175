import React from "react";

import Nav from "../../components/nav/Nav";
import { motion } from "framer-motion";

import "./contacts.css";
import ContactForm from "../../components/form/ContactForm";
import whatsapp from "../../img/icons/whatsapp-icon.svg";
import instagram from "../../img/icons/instagram-icon.svg";
import gsap from "gsap";
import Transition from "../../components/transition/Transition";
import PolicyPopup from "../../components/policyPopup/PolicyPopup";

const titleVariant = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 2,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

const linkVariant = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 2,
      ease: [0.6, -0.05, 0.01, 0.99],
      delay: 0.3,
    },
  },
};

const Contacts = ({ popup }) => {
  const navLogoPosition = {
    left: "30px",
    transform: "translateX(0)",
  };
  const contactTl = gsap.timeline();
  return (
    <div className="contact">
      {popup && <PolicyPopup />}
      <Transition timeline={contactTl} />
      <Nav position={navLogoPosition} />
      <div className="page">
        <div className="contact-hero">
          <div className="contact-title">
            <motion.h1
              variants={titleVariant}
              initial="initial"
              animate="animate"
            >
              Connect us
            </motion.h1>
          </div>
        </div>
        <div className="contact-content">
          <ContactForm />
          <motion.div
            className="contact-title-links"
            variants={linkVariant}
            initial="initial"
            animate="animate"
          >
            <div className="contact-social-links">
              <a
                href="https://wa.me/380674459055"
                target="_blank"
                rel="noreferrer"
              >
                <img src={whatsapp} alt="" />
              </a>

              <a
                href="https://www.instagram.com/wedlifer/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="" />
              </a>
            </div>
            <a href="mailto:wedlifer@gmail.com">wedlifer@gmail.com</a>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
