import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./projects.css";
import { projects } from "./config";
import { posters } from "./config";
import Nav from "../../components/nav/Nav";

import ProjectItem from "./ProjectItem";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
import Instagram from "../../components/instagram/Instagram";
import useWindowDimensions from "../../components/hooks/useWindowDimensions";
import ParallaxText from "../../components/parallaxText/ParallaxText";
import Loader1 from "../../components/loader/Loader1";
import Transition from "../../components/transition/Transition";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import PolicyPopup from "../../components/policyPopup/PolicyPopup";

const Projects = ({ popup }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { height, width } = useWindowDimensions();
  const sizeMob = width < 1024;
  const progectsTl = gsap.timeline();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.sessionStorage.getItem("loader")) {
      setIsLoading(false);
    }
  }, []);

  const navLogoPosition = {
    left: "30px",
    transform: "translateX(0)",
  };

  useGSAP(() => {
    gsap.from(".projects__list", {
      width: "96%",
      delay: 0.05,
      duration: 2,
      ease: "power3.out",
    });
    if (!isLoading) {
      gsap.to(".loader-wrap", {
        opacity: 0,
        display: "none",
      });
    }
  });

  return (
    <div className="projects">
      {popup && <PolicyPopup />}
      <Transition timeline={progectsTl} />

      <div className="loader-wrap">{isLoading && <Loader1 />}</div>

      <>
        <Nav position={navLogoPosition} />
      </>
      {sizeMob ? (
        <ul className="projects__list">
          {posters?.map((item, i) => (
            <li key={item.id}>
              <NavLink to={`/project/${item.urlName}`}>
                <div>
                  <div>
                    <img
                      src={item.imgPrev}
                      id={item.id}
                      alt=""
                      className="projects__img"
                    />
                  </div>
                </div>
                <div className="projects__item-info">
                  <span className="projects__item-title">{item.title}</span>
                  <span className="projects__item-location">
                    {item.location}
                  </span>
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      ) : (
        <ul className="projects__list">
          {projects &&
            projects.map((item, i) => (
              <li key={item.id}>
                <NavLink to={`/project/${item.urlName}`}>
                  <div>
                    <ProjectItem item={item} />
                  </div>
                  <div className="projects__item-info">
                    <span className="projects__item-title">{item.title}</span>
                    <span className="projects__item-location">
                      {item.location}
                    </span>
                  </div>
                </NavLink>
              </li>
            ))}
        </ul>
      )}

      <VideoPlayer />
      <section className="parallaxText">
        <ParallaxText baseVelocity={-0.5}>
          OUR INSTAGRAM OUR INSTAGRAM OUR INSTAGRAM
        </ParallaxText>
        <ParallaxText baseVelocity={0.5}>
          OUR INSTAGRAM OUR INSTAGRAM OUR INSTAGRAM
        </ParallaxText>
      </section>
      <Instagram />
    </div>
  );
};

export default Projects;
