import React from "react";
import Nav from "../../components/nav/Nav";
import Footer from "../../components/footer/Footer";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import Transition from "../../components/transition/Transition";
import "./privacy.css";
gsap.registerPlugin(ScrollTrigger);

const Privacy = () => {
  const navLogoPosition = {
    left: "30px",
    transform: "translateX(0)",
  };
  const privacyTl = gsap.timeline();

  useGSAP(() => {
    const myText = new SplitType(".page-h1 h1");
    const pageH1Tl = gsap.timeline();
    pageH1Tl.to(".line .word", {
      y: 0,
      stagger: 0.15,
      delay: 0.1,
      duration: 1.4,
      ease: "power3.out",
    });

    const boxes = gsap.utils.toArray(".page-p p");
    boxes.forEach((box) => {
      gsap.to(box, {
        y: 0,
        opacity: 1,
        duration: 1,
        ease: [0.6, -0.05, 0.01, 0.99],
        scrollTrigger: {
          trigger: box,
        },
      });
    });
  });

  return (
    <>
      <div className="privacy">
        <Transition timeline={privacyTl} />

        <Nav position={navLogoPosition} />

        <div className="privacy-section">
          <div className="page-h1">
            <h1>
              <span>Privacy</span>
              <span>Policy</span>
            </h1>
          </div>
          <div className="page-p">
            <p>
              This Privacy Policy governs the manner in which Wedlifer collects,
              uses, maintains and discloses information collected from users of
              the www.wedlifer.com. This privacy policy applies to the Site and
              all products and services offered by Wedlifer.
            </p>
          </div>
          <div className="page-h4">
            <h4> PERSONAL IDENTIFICATION INFORMATION</h4>
          </div>
          <div className="page-p">
            <p>
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, place an order, and in connection with other activities,
              services, features or resources we make available on our Site.
              Users may be asked for, as appropriate, name, email address. Users
              may, however, visit our Site anonymously. We will collect personal
              identification information from Users only if they voluntarily
              submit such information to us. Users can always refuse to supply
              personally identification information, except that it may prevent
              them from engaging in certain Site related activities.
            </p>
          </div>
          <div className="page-h4">
            <h4>NON-PERSONAL IDENTIFICATION INFORMATION </h4>
          </div>
          <div className="page-p">
            <p>
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer and
              technical information about Users means of connection to our Site,
              such as the operating system and the Internet service providers
              utilized and other similar information.
            </p>
          </div>
          <div className="page-h4">
            <h4>WEB BROWSER COOKIES </h4>
          </div>
          <div className="page-p">
            <p>
              Our Site may use “cookies” to enhance User experience. User’s web
              browser places cookies on their hard drive for record-keeping
              purposes and sometimes to track information about them. User may
              choose to set their web browser to refuse cookies, or to alert you
              when cookies are being sent. If they do so, note that some parts
              of the Site may not function properly.
            </p>
          </div>
          <div className="page-h4">
            <h4>HOW WE USE COLLECTED INFORMATION </h4>
          </div>
          <div className="page-p">
            <p>
              Wedlifer may collect and use Users personal information for the
              following purposes: * To run and operate our Site * We may need
              your information display content on the Site correctly. * To
              improve customer service * Information you provide helps us
              respond to your customer service requests and support needs more
              efficiently. * To personalize user experience * We may use
              information in the aggregate to understand how our Users as a
              group use the services and resources provided on our Site. * To
              improve our Site * We may use feedback you provide to improve our
              products and services. * To process payments * We may use the
              information Users provide about themselves when placing an order
              only to provide service to that order. We do not share this
              information with outside parties except to the extent necessary to
              provide the service. * To send periodic emails * We may use the
              email address to send User information and updates pertaining to
              their order. It may also be used to respond to their inquiries,
              questions, and/or other requests.
            </p>
          </div>
          <div className="page-h4">
            <h4>HOW WE PROTECT YOUR INFORMATION </h4>
          </div>
          <div className="page-p">
            <p>
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site.
            </p>
          </div>
          <div className="page-h4">
            <h4>SHARING YOUR PERSONAL INFORMATION </h4>
          </div>
          <div className="page-p">
            <p>
              We do not sell, trade, or rent Users personal identification
              information to others. We may share generic aggregated demographic
              information not linked to any personal identification information
              regarding visitors and users with our business partners, trusted
              affiliates and advertisers for the purposes outlined above.
            </p>
          </div>
          <div className="page-h4">
            <h4>CHANGES TO THIS PRIVACY POLICY </h4>
          </div>
          <div className="page-p">
            <p>
              Wedlifer has the discretion to update this privacy policy at any
              time. When we do, we will post a notification on the main page of
              our Site, revise the updated date at the bottom of this page and
              send you an email. We encourage Users to frequently check this
              page for any changes to stay informed about how we are helping to
              protect the personal information we collect. You acknowledge and
              agree that it is your responsibility to review this privacy policy
              periodically and become aware of modifications.
            </p>
          </div>
          <div className="page-h4">
            <h4> YOUR ACCEPTANCE OF THESE TERMS </h4>
          </div>
          <div className="page-p">
            <p>
              By using this Site, you signify your acceptance of this policy. If
              you do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>
          </div>
          <div className="page-h4">
            <h4>CONTACTING US</h4>
          </div>
          <div className="page-p">
            <p>
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please contact us.
              This document was last updated on November 21st, 2024
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
