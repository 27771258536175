export const reviews = [
  {
    id: 1,
    imgUser: "/img/reviews/rev-user-1.jpg",
    imgPos: "right",
    name: "Alexander & Anastasia ",
    dateEvent: "Married couple on 05 July 2024",
    text: "Good morning! I am impressed by how beautiful the photos are! 😍😍😍 I will be making a post with them; can I create a joint publication? Thank you for your professionalism!! 🥰🥰",
    wFull: null,
  },
  {
    id: 2,
    imgUser: "/img/reviews/rev-user-2.jpg",
    imgPos: "left",
    name: "Konstantin & Michel",
    dateEvent: "Married couple on 07 Oct 2023",
    text: "They are beautiful! I can imagine how much work they were, but so so worth it! We are very happy u Incredible pictures and you caught the atmosphere perfectly!! Thank you so much again and I will obviously recommend you guys to everyone I know.",
    wFull: null,
  },
  {
    id: 3,
    imgUser: "/img/reviews/rev-user-3.jpg",
    imgPos: "right",
    name: "Ted & Jamie",
    dateEvent: "Married couple on 11 Mar 2023",
    text: `THEY ARE AMAZING!!!
    There are so many absolutely perfect ones, true gems
    I’m so happy with them! Wow wow
    WOW
    Thank you so much!`,
    wFull: "full",
  },
  {
    id: 4,
    imgUser: "/img/reviews/rev-user-4.jpg",
    imgPos: "right",
    name: "Bryan & Adan",
    dateEvent: "Married couple on 03 August 2024",
    text: "Hi! These photos are just incredible, we are delighted! We have looked through them a million times and every photo is woooow!❤️‍🔥",
    wFull: null,
  },
  {
    id: 5,
    imgUser: "/img/reviews/rev-user-5.jpg",
    imgPos: "left",
    name: "Katty & Mike",
    dateEvent: "Married couple on 24 Jun 2023",
    text: "Hi Igor!! We just got our photos back today and I could not be happier with them a you are so talented and captured all the moments so perfectly thank you so much for your attention, patience, and support throughout our entire photoshoot, you made it so fun and unforgettable we are so happy to have had you as our photographer for our engagement shoot in beautiful Lake Como.",
    wFull: null,
  },
];
