import React, { useState } from "react";
import "./policyPopup.css";

const PolicyPopup = () => {
  const [popup, setPopup] = useState(true);

  const cookieAccept = () => {
    localStorage.setItem("isCookie", "true");
    setPopup(false);
  };

  return (
    <>
      {popup && (
        <div className="cookie-bar">
          <div className="cookie-bar-inner">
            <div className="cookie-bar-message">
              <p className="cookie-text">
                We use cookies to provide a better experience. By using this
                site you accept our cookie policy.
              </p>
            </div>
            <div className="cookie-bar-buttons">
              <button
                id="cookie-bar-btn"
                className=""
                type="button"
                onClick={cookieAccept}
              >
                GOT IT
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PolicyPopup;
