import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { wrap } from "@popmotion/popcorn";

import ProjectImgItem from "./ProjectImgItem";

const ProjectItem = ({ item }) => {
  const [slide, setSlide] = useState(0);
  const slideIndex = wrap(0, 4, slide);

  return (
    <div>
      {item.imgListSrc
        .filter((_, iterator) => iterator === slideIndex)
        .map((el, index) => (
          <ProjectImgItem
            el={el}
            slide={slide}
            key={item.id}
            setSlide={setSlide}
          />
        ))}
    </div>
  );
};

export default ProjectItem;
