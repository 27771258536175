import React from "react";
import "./loaderLight.css";

const LoaderLight = () => {
  return (
    <>
      <div className="loader loader-light">
        <span className="logo-loader">
          <h1>WEDLIFER</h1>

          <span className="subtitle">studio</span>
        </span>
      </div>
    </>
  );
};

export default LoaderLight;
