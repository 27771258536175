import { v4 as uuidv4 } from "uuid";

export const posters = [
  //  #1posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/01/1.JPG",
    title: "Vladyslav & Angelina",
    location: "Lake Como",
    urlName: "Vladyslav&Angelina",
  },
  //  #2posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/1m/1.jpg",
    title: "Pavel & Alina",
    location: "Lake Como",
    urlName: "Pavel&Alina",
  },
  //  #3posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/2/1.jpg",
    title: "Zinchenko A & V",
    location: "Kiyv",
    urlName: "Zinchenko&V",
  },
  //  #4posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/04/1.JPG",
    title: "Adan & Brian",
    location: "Lake Garda",
    urlName: "Adan&Brian",
  },
  //  #5posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/05/1.JPG",
    title: "Yuriy & Natalia",
    location: "Burgenstock Switzerland",
    urlName: "Yuriy&Natalia",
  },

  //  #6posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/3/1.jpg",
    title: "Ivan & Margarita",
    location: "Como Italy",
    urlName: "Ivan&Margarita",
  },
  //  #7posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/4/1.jpg",
    title: "Gerard & Yuly",
    location: "Paris",
    urlName: "Gerard&Yuly",
  },
  //  #8posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/5/1.jpg",
    title: "Saeed & Donia",
    location: "Lake Maggiore",
    urlName: "Saeed&Donia",
  },
  //  #9posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/6/1.JPG",
    title: "Max & Nadin",
    location: "Vienna - Luxembourg ",
    urlName: "Max&Nadin",
  },
  //  #10posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/7/1.JPG",
    title: "Dorin & Tatiana",
    location: "Monaco",
    urlName: "Dorin&Tatiana",
  },
  //  #11posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/8/1.JPG",
    title: "Jeanloup & Amelia",
    location: "Roma",
    urlName: "Jeanloup&Amelia",
  },
  //  #12posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/9/1.JPG",
    title: "Enrico & Anna",
    location: "Lake Como",
    urlName: "Enrico&Anna",
  },
  //  #13posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/10/1.JPG",
    title: "Luca & Alessandro",
    location: "Toscana",
    urlName: "Luca&Alessandro",
  },
  //  #14posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/012/1.JPG",
    title: "Jerome & Daria",
    location: "Lake Como",
    urlName: "Jerome&Daria",
  },
  //  #15posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/11/1.JPG",
    title: "Chris & Layla",
    location: "Lake Como",
    urlName: "Chris&Layla",
  },
  //  #16posters
  {
    id: uuidv4(),
    imgPrev: "/img/projects/12/1.JPG",
    title: "David & Vlada",
    location: "Paris",
    urlName: "David&Vlada",
  },
];

export const projects = [
  // #1
  {
    id: uuidv4(),
    imgPrev: "/img/projects/01/1.JPG",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/01/1.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/4.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/01/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/01/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/01/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/77.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/78.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/01/79.JPG",
      },
    ],
    title: "Vladyslav & Angelina",
    location: "Lake Como",
    urlName: "Vladyslav&Angelina",
  },
  // #2
  {
    id: uuidv4(),
    imgPrev: "/img/projects/1m/1.jpg",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/1m/1.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/4.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/17.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/18.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/21.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/22.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/24.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/25.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/26.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/27.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/28.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/29.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/30.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/31.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/32.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/36.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/37.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/38.jpg",
      },
      { id: uuidv4(), src: "/img/projects/1m/39.jpg" },
      {
        id: uuidv4(),
        src: "/img/projects/1m/40.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/41.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/42.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/43.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/44.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/45.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/46.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/47.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/48.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/49.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/50.jpg",
      },

      {
        id: uuidv4(),
        src: "/img/projects/1m/51.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/52.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/53.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/54.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/55.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/56.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/59.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/60.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/61.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/62.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/63.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/64.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/65.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/66.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/67.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/73.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/76.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/77.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/78.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/79.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/80.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/81.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/82.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/83.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/84.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/85.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/86.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/87.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/88.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/89.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/90.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/91.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/92.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/93.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/94.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/95.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/96.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/97.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/98.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/99.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/100.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/101.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/102.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/103.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/104.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/105.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/106.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/107.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/108.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/109.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/110.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/111.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/112.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/113.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/114.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/115.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/116.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/117.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/118.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/119.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/120.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/121.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/122.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/123.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/124.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/125.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/126.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/127.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/128.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/129.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/130.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/131.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/132.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/133.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/134.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/135.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/136.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/137.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/138.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/139.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/140.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/141.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/142.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/143.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/144.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/145.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/146.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/147.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/148.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/149.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1m/150.JPG",
      },
    ],
    title: "Pavel & Alina",
    location: "Lake Como",
    urlName: "Pavel&Alina",
  },
  // #3
  {
    id: uuidv4(),
    imgPrev: "/img/projects/2/1.jpg",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/2/1.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/2.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/3.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/4.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/5.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/6.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/38.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/39.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/2/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/2/77.JPG",
      },
    ],
    title: "Zinchenko A & V",
    location: "Kiyv",
    urlName: "Zinchenko&V",
  },
  // #4
  {
    id: uuidv4(),
    imgPrev: "/img/projects/04/1.JPG",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/04/1.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/4.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/04/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/04/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/04/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/04/77.jpg",
      },
    ],
    title: "Adan & Brian",
    location: "Lake Garda",
    urlName: "Adan&Brian",
  },
  // #5
  {
    id: uuidv4(),
    imgPrev: "/img/projects/05/1.JPG",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/05/1.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/4.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/05/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/05/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/05/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/05/66.JPG",
      },
    ],
    title: "Yuriy & Natalia",
    location: "Burgenstock Switzerland",
    urlName: "Yuriy&Natalia",
  },
  // #6
  {
    id: uuidv4(),
    imgPrev: "/img/projects/3/1.jpg",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/3/1.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/2.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/3.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/4.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/3/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/3/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/3/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/77.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/78.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/79.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/80.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/81.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/82.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/83.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/84.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/85.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/86.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/87.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/88.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/89.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/90.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/91.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/92.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/93.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/94.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/95.JPG",
      },
    ],
    title: "Ivan & Margarita",
    location: "Como Italy",
    urlName: "Ivan&Margarita",
  },

  // #6

  // #7
  {
    id: uuidv4(),
    imgPrev: "/img/projects/4/1.jpg",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/4/1.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/2.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/3.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/4.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/4/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/4/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/3/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/4/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/4/77.JPG",
      },
    ],
    title: "Gerard & Yuly",
    location: "Paris",
    urlName: "Gerard&Yuly",
  },
  // #8
  {
    id: uuidv4(),
    imgPrev: "/img/projects/5/1.jpg",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/5/1.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/2.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/3.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/4.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/5/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/5/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/5/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/77.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/78.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/79.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/80.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/81.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/82.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/83.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/84.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/85.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/86.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/87.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/88.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/89.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/90.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/91.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/92.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/93.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/94.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/95.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/96.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/97.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/98.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/99.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/100.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/5/101.JPG",
      },
    ],
    title: "Saeed & Donia",
    location: "Lake Maggiore",
    urlName: "Saeed&Donia",
  },
  // #9
  {
    id: uuidv4(),
    imgPrev: "/img/projects/6/1.JPG",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/6/1.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/4.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/6/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/6/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/6/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/77.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/78.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/79.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/80.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/81.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/82.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/83.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/84.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/85.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/86.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/87.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/88.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/89.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/90.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/91.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/92.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/93.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/94.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/95.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/96.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/97.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/98.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/99.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/100.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/101.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/102.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/103.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/104.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/105.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/106.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/107.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/108.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/109.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/110.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/111.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/112.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/113.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/114.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/115.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/116.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/6/117.JPG",
      },
    ],
    title: "Max & Nadin",
    location: "Vienna - Luxembourg ",
    urlName: "Max&Nadin",
  },
  // #10
  {
    id: uuidv4(),
    imgPrev: "/img/projects/7/1.JPG",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/7/1.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/4.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/7/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/7/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/7/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/77.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/78.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/79.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/80.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/81.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/82.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/83.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/84.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/85.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/86.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/87.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/88.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/89.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/90.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/91.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/92.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/93.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/94.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/95.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/96.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/97.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/98.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/99.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/100.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/101.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/102.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/103.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/104.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/105.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/106.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/107.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/108.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/109.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/110.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/111.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/112.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/113.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/114.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/7/115.JPG",
      },
    ],
    title: "Dorin & Tatiana",
    location: "Monaco",
    urlName: "Dorin&Tatiana",
  },
  // #11
  {
    id: uuidv4(),
    imgPrev: "/img/projects/8/1.JPG",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/8/1.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/8/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/4.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/8/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/8/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/8/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/1/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/77.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/78.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/79.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/80.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/81.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/82.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/83.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/84.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/85.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/86.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/87.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/88.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/89.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/90.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/91.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/92.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/93.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/94.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/95.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/96.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/97.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/98.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/99.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/100.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/101.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/102.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/103.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/104.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/105.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/106.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/107.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/108.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/109.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/110.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/111.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/112.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/113.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/114.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/115.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/116.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/117.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/118.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/119.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/120.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/121.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/122.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/8/123.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/124.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/125.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/126.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/127.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/128.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/129.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/130.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/131.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/132.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/133.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/134.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/135.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/136.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/137.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/138.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/139.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/140.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/141.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/142.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/143.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/144.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/8/145.JPG",
      },
    ],
    title: "Jeanloup & Amelia",
    location: "Roma",
    urlName: "Jeanloup&Amelia",
  },
  // #12
  {
    id: uuidv4(),
    imgPrev: "/img/projects/012/1.JPG",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/012/1.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/4.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/012/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/012/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/012/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/012/72.JPG",
      },
    ],
    title: "Jerome & Daria",
    location: "Lake Como",
    urlName: "Jerome&Daria",
  },
  {
    id: uuidv4(),
    imgPrev: "/img/projects/9/1.JPG",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/9/1.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/4.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/9/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/9/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/9/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/77.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/78.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/79.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/80.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/81.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/82.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/83.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/84.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/85.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/86.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/87.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/88.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/89.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/90.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/91.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/92.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/93.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/94.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/95.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/96.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/9/97.JPG",
      },
    ],
    title: "Enrico & Anna",
    location: "Lake Como",
    urlName: "Enrico&Anna",
  },
  // #13
  {
    id: uuidv4(),
    imgPrev: "/img/projects/10/1.JPG",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/10/1.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/4.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/18.jpg",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/10/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/10/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/10/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/77.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/78.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/79.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/80.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/81.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/82.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/10/83.JPG",
      },
    ],
    title: "Luca & Alessandro",
    location: "Toscana",
    urlName: "Luca&Alessandro",
  },
  // #14
  {
    id: uuidv4(),
    imgPrev: "/img/projects/11/1.JPG",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/11/1.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/4.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/11/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/11/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/11/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/11/76.JPG",
      },
    ],
    title: "Chris & Layla",
    location: "Lake Como",
    urlName: "Chris&Layla",
  },
  // #15
  {
    id: uuidv4(),
    imgPrev: "/img/projects/12/1.JPG",
    imgListSrc: [
      {
        id: uuidv4(),
        src: "/img/projects/12/1.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/2.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/3.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/4.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/5.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/6.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/7.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/8.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/9.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/10.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/11.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/12.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/13.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/14.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/15.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/16.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/17.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/18.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/19.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/20.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/21.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/22.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/23.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/24.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/25.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/26.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/27.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/28.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/29.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/30.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/31.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/32.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/33.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/34.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/35.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/36.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/37.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/38.JPG",
      },
      { id: uuidv4(), src: "/img/projects/12/39.JPG" },
      {
        id: uuidv4(),
        src: "/img/projects/12/40.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/41.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/42.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/43.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/44.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/45.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/46.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/47.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/48.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/49.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/50.JPG",
      },

      {
        id: uuidv4(),
        src: "/img/projects/12/51.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/52.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/53.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/54.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/55.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/56.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/57.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/58.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/59.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/60.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/61.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/62.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/63.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/64.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/65.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/66.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/67.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/68.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/69.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/70.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/71.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/72.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/73.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/74.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/75.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/76.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/77.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/78.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/79.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/80.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/81.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/82.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/83.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/84.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/85.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/86.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/87.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/88.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/89.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/90.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/91.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/92.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/93.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/94.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/95.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/96.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/97.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/98.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/99.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/100.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/101.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/102.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/103.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/104.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/105.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/106.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/107.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/108.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/109.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/110.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/111.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/112.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/113.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/114.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/115.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/116.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/117.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/118.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/119.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/120.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/121.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/122.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/123.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/124.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/125.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/126.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/127.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/128.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/129.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/130.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/131.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/132.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/133.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/134.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/135.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/136.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/137.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/138.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/139.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/140.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/141.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/142.JPG",
      },
      {
        id: uuidv4(),
        src: "/img/projects/12/143.JPG",
      },
    ],
    title: "David & Vlada",
    location: "Paris",
    urlName: "David&Vlada",
  },
];
