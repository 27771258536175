import React, { useEffect } from "react";
import img1 from "../../img/footer/img1.jpg";
import img2 from "../../img/footer/img2.jpg";
import img3 from "../../img/footer/img3.jpeg";
import img4 from "../../img/footer/img4.jpg";
import img5 from "../../img/footer/img5.jpg";
import img6 from "../../img/footer/img6.jpg";
import img7 from "../../img/footer/img7.jpg";
import "./footerImgList.css";
import gsap from "gsap";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

const FooterImgList = () => {
  const { width } = useWindowDimensions();
  const sizeMob = width < 1024;

  // useGSAP(() => {
  //   const tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: ".footer-img-list",
  //       start: !sizeMob ? "40% bottom" : "0 bottom",
  //       scrub: 1,
  //       // markers: true,
  //       end: !sizeMob ? "-30%" : "-200%",
  //     },
  //   });

  //   tl.from(".footer-img:nth-child(even)", {
  //     y: -150,
  //     opacity: 0,
  //     ease: [0.6, -0.05, 0.01, 0.99],
  //   });
  //   tl.from(".footer-img:nth-child(odd)", {
  //     y: 150,
  //     opacity: 0,
  //     ease: [0.6, -0.05, 0.01, 0.99],
  //   });
  // });

  return (
    <div className="footer-img-list-wrap">
      <div className="footer-img-list">
        <div className="footer-img footer-img-1">
          <img src={img1} alt="" />
        </div>
        <div className="footer-img footer-img-2">
          <img src={img2} alt="" />
        </div>
        <div className="footer-img footer-img-3">
          <img src={img3} alt="" />
        </div>
        <div className="footer-img footer-img-4">
          <img src={img4} alt="" />
        </div>
        <div className="footer-img footer-img-5">
          <img src={img5} alt="" />
        </div>
        <div className="footer-img footer-img-6">
          <img src={img6} alt="" />
        </div>
        <div className="footer-img footer-img-7">
          <img src={img7} alt="" />
        </div>
      </div>
    </div>
  );
};

export default FooterImgList;
