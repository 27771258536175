import img1 from "../../img/footer/img1.jpg";
import img2 from "../../img/footer/img2.jpg";
import img3 from "../../img/footer/img3.jpeg";
import img4 from "../../img/footer/img4.jpg";
import img5 from "../../img/footer/img5.jpg";
import img6 from "../../img/footer/img6.jpg";

export const navigation = [
  { id: 1, link: "/", text: "Portfolio", imgSrc: img1, urlText: " " },
  {
    id: 2,
    link: "/gallery/",
    text: "Gallery",
    imgSrc: img2,
  },
  { id: 3, link: "/about/", text: "About Us", imgSrc: img3 },
  // { id: 4, link: "/price/", text: "Price", imgSrc: img4,  },
  {
    id: 5,
    link: "/reviews/",
    text: "Reviews",
    imgSrc: img5,
  },
  {
    id: 6,
    link: "/contacts/",
    text: "Contacts",
    imgSrc: img6,
  },
];
