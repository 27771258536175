import React, { useRef } from "react";
import { motion } from "framer-motion";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const ProjectDetailsHero = ({
  refScrl,

  location,
  title,
  imgPrev,
  handleClickScroll,
  sizeMob,
}) => {
  useGSAP(() => {
    gsap.from(".project-details__text-anim", {
      y: -50,
      opacity: 0,
      duration: 1.5,
      ease: [0.6, -0.05, 0.01, 0.99],
      delay: 0.3,
      stagger: 0.2,
    });
    gsap.from(".project-details__img", {
      x: -80,
      opacity: 0,
      duration: 1.5,
      ease: [0.6, -0.05, 0.01, 0.99],
    });
  });

  return (
    <div ref={refScrl} className="project-details__text-container">
      <div className="project-details__text-wrap">
        <div className="project-details__text">
          <div className="project-details__location project-details__text-anim">
            {location}
          </div>
          <div className="project-details__title project-details__text-anim">
            {title}
          </div>
        </div>
      </div>
      <div className="project-details__img-wrap">
        <div
          className="project-details__img"
          style={{
            height: "100vh",
            width: "100%",
            backgroundImage: `url(..${imgPrev})`,
            backgroundSize: "cover",
            //  backgroundSize: fullWidth ? "cover" : "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>

      {!sizeMob ? (
        <div className="mouse" onClick={handleClickScroll}></div>
      ) : (
        <div className="touch-icon-mob swipe">SWIPE LEFT FOR MORE</div>
      )}
    </div>
  );
};

export default ProjectDetailsHero;
