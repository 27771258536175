export const imagesLoader = [
  { id: 1, src: "/img/loader/1.JPG" },
  { id: 2, src: "/img/loader/2.JPG" },
  { id: 3, src: "/img/loader/3.JPG" },
  { id: 4, src: "/img/loader/4.JPG" },
  { id: 5, src: "/img/loader/5.JPG" },
  { id: 6, src: "/img/loader/6.JPG" },
  { id: 7, src: "/img/loader/7.JPG" },
  { id: 8, src: "/img/loader/8.JPG" },
  { id: 9, src: "/img/loader/9.JPG" },
  { id: 10, src: "/img/loader/10.JPG" },
  { id: 11, src: "/img/loader/11.JPG" },
  { id: 12, src: "/img/loader/12.JPG" },
  { id: 13, src: "/img/loader/13.JPG" },
  { id: 14, src: "/img/loader/14.JPG" },
  { id: 15, src: "/img/loader/15.JPG" },
  { id: 16, src: "/img/loader/16.JPG" },
  { id: 17, src: "/img/loader/17.JPG" },
  { id: 18, src: "/img/loader/18.JPG" },
  { id: 19, src: "/img/loader/19.JPG" },
  { id: 20, src: "/img/loader/20.JPG" },
  { id: 21, src: "/img/loader/21.JPG" },
  { id: 22, src: "/img/loader/22.JPG" },
  { id: 23, src: "/img/loader/23.JPG" },
  { id: 24, src: "/img/loader/24.JPG" },
  { id: 25, src: "/img/loader/25.JPG" },
  { id: 26, src: "/img/loader/26.JPG" },
  { id: 27, src: "/img/loader/27.JPG" },
  { id: 28, src: "/img/loader/28.JPG" },
  { id: 29, src: "/img/loader/29.JPG" },
  { id: 30, src: "/img/loader/30.JPG" },
];
