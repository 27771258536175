import React from "react";
import "./popup.css";

const Popup = ({ msg }) => {
  return (
    <div className="popup-wrapper">
      <div className="popup">
        <span>{msg}</span>
      </div>
    </div>
  );
};

export default Popup;
