import React from "react";
import Link from "./Link";
import { motion } from "framer-motion";

const NavItem = ({ link, text, toggleMenu, idx, open, imgSrc }) => {
  return (
    open && (
      <li onClick={toggleMenu}>
        <motion.div
          className="nav-link-wrap"
          initial={{ opacity: 0, translateY: -50 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ duration: 0.2, delay: idx * 0.1 }}
        >
          <>
            <Link imgSrc={imgSrc} text={text} link={link} open={open} idx={idx}>
              {text}
            </Link>
          </>
        </motion.div>
      </li>
    )
  );
};

export default NavItem;
