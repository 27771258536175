import React, { useRef, useEffect } from "react";
import "./transition.css";
function Transition({ timeline }) {
  const trans = useRef(null);
  useEffect(() => {
    timeline.to(trans.current, {
      duration: 1.5,
      delay: 0.0001,
      xPercent: 100,
      ease: "power3.out",
    });
  });
  return <div className="transition-effect" ref={trans}></div>;
}

export default Transition;
