import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Projects from "./pages/projects/Projects";
import Contacts from "./pages/contacts/Contacts";
import ProjectDetails from "./pages/projects/ProjectDetails";
import About from "./pages/about/About";
import Gallery from "./pages/gallery/Gallery";
import Price from "./pages/price/Price";
import Reviews from "./pages/reviews/Reviews";
import Privacy from "./pages/privacy/Privacy";
import Terms from "./pages/privacy/Terms";

function App() {
  const [popup, setPopup] = useState(true);
  const checkCookie = () => {
    if (!popup) return;
    if (localStorage.getItem("isCookie")) {
      setPopup(false);
    } else if (!localStorage.getItem("isCookie")) {
      setPopup(true);
    }
  };

  useEffect(() => {
    checkCookie();
  }, []);

  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Projects popup={popup} />} />
          <Route path="/about" element={<About popup={popup} />} />
          <Route path="/gallery" element={<Gallery popup={popup} />} />
          <Route path="/reviews" element={<Reviews popup={popup} />} />
          <Route path="/price" element={<Price />} />
          <Route path="/contacts" element={<Contacts popup={popup} />} />
          <Route
            path="/project/:urlName"
            element={<ProjectDetails popup={popup} />}
          />
          <Route path="/privacy" element={<Privacy popup={popup} />} />
          <Route path="/terms" element={<Terms popup={popup} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
