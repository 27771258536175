import React, { useState, useEffect } from "react";
import Nav from "../../components/nav/Nav";
import imgHero from "../../img/about/hero-img.jpg";
import imgAbout1 from "../../img/about/about-img-1.png";
import imgAbout2 from "../../img/about/about-img-2.png";
import LoaderLight from "../../components/loaderLight/LoaderLight";

import "./about.css";
import Footer from "../../components/footer/Footer";
import FooterImgList from "../../components/footerImgList/FooterImgList";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import Transition from "../../components/transition/Transition";
import PolicyPopup from "../../components/policyPopup/PolicyPopup";

gsap.registerPlugin(ScrollTrigger);

const About = ({ popup }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navLogoPosition = {
    left: "30px",
    transform: "translateX(0)",
  };

  const aboutTl = gsap.timeline();

  useGSAP(() => {
    gsap.from(".about-hero-title h1", {
      y: -40,
      ease: "power3.out",
      duration: 1.5,
    });
    gsap.from(".about-hero-img", {
      y: -40,
      delay: 0.1,
      ease: "power3.out",
      duration: 1.5,
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-title",
        scrub: 1,
      },
    });

    tl.from(".section-title", {
      y: -150,
      opacity: 0,
      duration: 2,
      ease: [0.6, -0.05, 0.01, 0.99],
      stagger: 0.2,
    });

    let masks = document.querySelectorAll(".mask-img");
    masks.forEach((mask) => {
      let image = mask.querySelector(".mask-img img");

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: mask,
          toggleActions: "restart none none reset",
        },
      });

      tl.set(mask, { autoAlpha: 1 });

      tl.from(mask, {
        xPercent: -100,
        ease: "power2.out",
        duration: 1.5,
      });
      tl.from(image, {
        xPercent: 100,
        scale: 1.3,
        delay: -1.5,
        ease: "power2.out",
        duration: 1.5,
      });
    });
  });

  let images = [imgHero, imgAbout1, imgAbout2];

  return (
    <>
      <div className="about">
        {popup && <PolicyPopup />}
        <Transition timeline={aboutTl} />
        {/* {isLoading && <LoaderLight />} */}
        <Nav position={navLogoPosition} />
        <div className="about-hero">
          <div className="page">
            <div className="about-hero-wrap">
              <div className="about-hero-title">
                <h1>
                  HELLO <br /> AND WARM <br /> <span>from</span>GREETINGS <br />{" "}
                  LAKE COMO
                </h1>
              </div>

              <div className="about-hero-img mask-img">
                <img src={imgHero} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="about-content">
          <div className="page">
            <div className="about-title section-title vis-hid">
              <h2>about us</h2>
            </div>
          </div>
          <div className="about-content-top">
            <div className="about-content-top-img">
              <div>
                <div className="mob">
                  <h5>MY WIFE AND I ARE A DYNAMIC DUO OF PHOTOGRAPHERS</h5>
                  <ul>
                    <li>WHO CALL THIS PICTURESQUE SETTING</li>
                    <li>OUR HOME. WITH A COMBINED</li>
                    <li>EXPERIENCE OF 14 YEARS IN THE INDUSTRY</li>
                    <li>WE HAVE HAD THE PRIVILEGE OF</li>
                    <li>CAPTURING OVER 300 WEDDINGS, RANGING </li>
                    <li>FROM GRAND-SCALE EVENTS TO INTIMATE</li>
                    <li>CEREMONIES</li>
                  </ul>
                </div>
                <div>
                  <div className="about-title section-title mob">
                    <h2>about us</h2>
                  </div>

                  <div className="mask-img">
                    <img src={imgAbout1} alt="" />
                  </div>
                </div>
              </div>

              <div className="about-content-bottom-text">
                <div className="about-text-in">
                  <p className="about-text">
                    OUR PHOTOGRAPHY STYLE SEAMLESSLY BLENDS THE SOPHISTICATION
                    OF A MAGAZINE EDITORIAL WITH THE CINEMATIC ALLURE OF
                    STORYTELLING, ENSURING THAT EVERY MOMENT IS BEAUTIFULLY
                    IMMORTALIZED.
                  </p>
                  <p className="about-text">
                    CARRYING TWO CAMERAS WITH DIFFERENT LENSES, WE ARE ALWAYS
                    READY TO ADAPT TO THE UNIQUE ATMOSPHERE OF EACH WEDDING,
                    CAPTURING BOTH THE INTRICATE DETAILS AND THE HEARTFELT
                    EMOTIONS THAT MAKE YOUR DAY TRULY SPECIAL.
                  </p>
                </div>
              </div>
            </div>
            <div className="about-content-top-text ">
              <div className="desc">
                <h5>MY WIFE AND I ARE A DYNAMIC DUO OF PHOTOGRAPHERS</h5>
                <ul>
                  <li>WHO CALL THIS PICTURESQUE SETTING</li>
                  <li>OUR HOME. WITH A COMBINED</li>
                  <li>EXPERIENCE OF 14 YEARS IN THE INDUSTRY</li>
                  <li>WE HAVE HAD THE PRIVILEGE OF</li>
                  <li>CAPTURING OVER 300 WEDDINGS, RANGING </li>
                  <li>FROM GRAND-SCALE EVENTS TO INTIMATE</li>
                  <li>CEREMONIES</li>
                </ul>
              </div>

              <div className="about-content-bottom-img">
                <div className="mask-img">
                  <img src={imgAbout2} alt="" />
                </div>
                <div className="about-img-text-top">
                  <span>14</span>
                  <p>years</p>
                </div>
                <div className="about-img-text-bot">
                  <span>300</span>
                  <p>weddings</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-footer">
          <div className="page about-section">
            <p className="footer-text-top">
              PHOTOGRAPHY IS NOT JUST A JOB FOR US, IT IS A DEEP-ROOTED PASSION
              THAT DRIVES US TO CREATE TIMELESS MEMORIES FOR OUR CLIENTS. WE
              WOULD BE THRILLED TO HEAR MORE ABOUT YOUR WEDDING VISION AND
              COLLABORATE WITH YOU TO CRAFT A COLLECTION OF PHOTOGRAPHS THAT
              PERFECTLY REFLECT YOUR LOVE STORY.
            </p>
          </div>

          <FooterImgList />
          <div className="page about-section">
            <p className="footer-text-bot">
              FEEL FREE TO SHARE YOUR THOUGHTS AND ASK ANY QUESTIONS YOU MAY
              HAVE — LET’S MAKE YOUR WEDDING DAY AN UNFORGETTABLE MASTERPIECE.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
