import React from "react";
import Nav from "../../components/nav/Nav";

import heroImg from "../../img/price/hero-img.jpg";
import img1 from "../../img/price/price-img-1.JPG";
import img2 from "../../img/price/price-img-2.jpg";
import img3 from "../../img/price/price-img-3.jpg";
import img4 from "../../img/price/price-img-4.JPG";
import img5 from "../../img/price/price-img-5.jpg";
import img from "../../img/price/price-img.JPG";
import footerImg from "../../img/price/footer-img.jpg";

import "./price.css";
import Footer from "../../components/footer/Footer";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Transition from "../../components/transition/Transition";
import SplitType from "split-type";
gsap.registerPlugin(ScrollTrigger);

const Price = () => {
  const navLogoPosition = {
    left: "30px",
    transform: "translateX(0)",
  };
  const priceTl = gsap.timeline();

  useGSAP(() => {
    const myText = new SplitType(".price h1");
    const priceHeroTl = gsap.timeline();
    priceHeroTl.to(".line .word", {
      y: 0,
      stagger: 0.15,
      delay: 0.1,
      duration: 1.4,
      ease: "power3.out",
    });

    let masks = document.querySelectorAll(".mask-img");
    masks.forEach((mask) => {
      let image = mask.querySelector(".mask-img img");

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: mask,
          start: "40% 60%",
          toggleActions: "restart none none reset",
        },
      });

      tl.set(mask, { autoAlpha: 1 });

      tl.from(mask, {
        yPercent: -100,
        ease: "power2.out",
        duration: 1.5,
      });
      tl.from(image, {
        yPercent: 100,
        scale: 1.3,
        delay: -1.5,
        ease: "power2.out",
        duration: 1.5,
      });
    });

    let tlF = gsap.timeline({
      scrollTrigger: {
        trigger: ".price-footer",
        start: "top 80%",
        scrub: true,
      },
    });

    tlF.from(".price-footer-img", {
      scale: 0.7,
      ease: "power2.out",
    });
  });

  return (
    <>
      <Transition timeline={priceTl} />
      <div className="price">
        <Nav position={navLogoPosition} />
        <div className="page">
          <h1 className="price-title">
            WEDDING <span className="price-title">PRICE</span>
            <span className="price-subtitle">package</span>
          </h1>
        </div>
        <div className="price-hero-img">
          <div>
            <img src={heroImg} alt="" />
          </div>
          <p className="price-section-subtitle">
            LETS START YOUR PERFECT STORY
          </p>
        </div>

        <div className="price-content">
          <div className="price-section">
            <h2 className="price-section-title">Photo</h2>
            <ul className="price-list">
              <li className="price-item">
                <div className="price-item-img mask-img">
                  <img src={img} alt="" />
                </div>
                <div className="price-item-text">
                  <h3 className="price-item-title">
                    CLASSIC<span>one</span>
                  </h3>
                  <ul className="price-item-list">
                    <li className="price-item-list-title">
                      COVERAGE & SESSION
                    </li>
                    <li>Up to 10 hours of wedding day coverage</li>
                    <li>One photographers</li>
                    <li>Color correction of all photos the client receives </li>
                    <li>300-400 photos</li>
                    <li>Retouching all close-ups</li>
                    <li className="price-item-list-title">PRODUCTS</li>
                    <li>High-resolution images via download</li>
                    <li>
                      All the footage will be stored for 6 months on the file
                      sharing service with the personal password
                    </li>
                  </ul>
                  <span className="price-item-number">€5 000</span>
                </div>
              </li>

              <li className="price-item">
                <div className="price-item-text">
                  <h3 className="price-item-title">
                    SPECIAL<span>two</span>
                  </h3>
                  <ul className="price-item-list">
                    <li className="price-item-list-title">
                      COVERAGE & SESSION
                    </li>
                    <li>Up to 12 hours of wedding day coverage</li>
                    <li>Two photographers</li>
                    <li>Color correction of all photos the client receives</li>
                    <li>700-800 photos</li>
                    <li>Retouching all close-ups</li>
                    <li className="price-item-list-title">PRODUCTS</li>
                    <li>High-resolution images via download</li>
                    <li>
                      All the footage will be stored for 6 months on the file
                      sharing service with the personal password
                    </li>
                  </ul>
                  <span className="price-item-number">€7 000</span>
                </div>
                <div className="price-item-img mask-img">
                  <img src={img1} alt="" />
                </div>
              </li>
              <li className="price-item">
                <div className="price-item-img mask-img">
                  <img src={img2} alt="" />
                </div>
                <div className="price-item-text">
                  <h3 className="price-item-title">
                    PREMIUM<span>three</span>
                  </h3>
                  <ul className="price-item-list">
                    <li className="price-item-list-title">
                      COVERAGE & SESSION
                    </li>
                    <li>Up to 12 hours of wedding day coverage</li>
                    <li>Pre-wedding party 3 hours</li>
                    <li>Three photographers</li>
                    <li>Color correction of all photos the client receives</li>
                    <li>1000+ photos</li>
                    <li>Retouching all close-ups</li>
                    <li>Premium photobook 30*30</li>
                    <li className="price-item-list-title">PRODUCTS</li>
                    <li>High-resolution images via download</li>
                    <li>
                      All the footage will be stored for 6 months on the file
                      sharing service with the personal password
                    </li>
                  </ul>
                  <span className="price-item-number">€10 000</span>
                </div>
              </li>
            </ul>
          </div>
          <div className="price-section price-section-video">
            <h2 className="price-section-title">Video</h2>
            <ul className="price-list">
              <li className="price-item">
                <div className="price-item-img mask-img">
                  <img src={img3} alt="" />
                </div>
                <div className="price-item-text">
                  <h3 className="price-item-title">
                    CLASSIC<span>one</span>
                  </h3>
                  <ul className="price-item-list">
                    <li className="price-item-list-title">
                      COVERAGE & SESSION
                    </li>
                    <li>Up to 8 hours of wedding day coverage</li>
                    <li>One cinematographer</li>
                    <li className="price-item-list-title">PRODUCTS</li>
                    <li>Cinematic film 7-10 minutes </li>
                    <li>Cinematic highlights 2 minutes</li>
                    <li>Documentary version WOWS & speeches</li>
                    <li>Drone footages</li>
                  </ul>
                  <span className="price-item-number">€5 000</span>
                </div>
              </li>
              <li className="price-item">
                <div className="price-item-text">
                  <h3 className="price-item-title">
                    SPECIAL<span>two</span>
                  </h3>
                  <ul className="price-item-list">
                    <li className="price-item-list-title">
                      COVERAGE & SESSION
                    </li>
                    <li>Up to 12 hours of wedding day coverage</li>
                    <li>Two cinematographer</li>
                    <li className="price-item-list-title">PRODUCTS</li>
                    <li>Cinematic film 10-15 minutes</li>
                    <li>Cinematic highlights 1,5 minutes</li>
                    <li>Documentary version WOWS & speeches</li>
                    <li>Drone footages</li>
                    <li>Insta-Reels 20 sec</li>
                  </ul>
                  <span className="price-item-number">€7 000</span>
                </div>
                <div className="price-item-img mask-img">
                  <img src={img4} alt="" />
                </div>
              </li>
              <li className="price-item">
                <div className="price-item-img mask-img">
                  <img src={img5} alt="" />
                </div>
                <div className="price-item-text">
                  <h3 className="price-item-title">
                    PREMIUM<span>three</span>
                  </h3>
                  <ul className="price-item-list">
                    <li className="price-item-list-title">
                      COVERAGE & SESSION
                    </li>
                    <li>Up to 12 hours of wedding day coverage</li>
                    <li>Three cinematographer</li>
                    <li>
                      One of the videographers films the pre-wedding party for 3
                      hours
                    </li>
                    <li className="price-item-list-title">PRODUCTS</li>
                    <li>Cinematic film 10-20 minutes</li>
                    <li>Cinematic highlights 2 minutes</li>
                    <li>Documentary version WOWS & speeches</li>
                    <li>Drone footages</li>
                    <li>Insta-Reels 20 sec</li>
                  </ul>
                  <span className="price-item-number">€10 000</span>
                </div>
              </li>
            </ul>
          </div>
          <p className="price-section-subtitle">CELEBRATE WITH US</p>
          <div className="price-footer">
            <div className="price-section">
              <h2 className="price-footer-title">
                <div>
                  <span className="bd-r">WEDDINGS</span>
                  <span className="bd-r">
                    <span className="italic">Love</span> STORY
                  </span>
                </div>
                <div>
                  <span className="bd-r">REPORTAGE</span>

                  <span>SPECIAL</span>
                </div>
              </h2>
              <div className="price-footer-img">
                <img src={footerImg} alt="" />
              </div>
              <div className="footer-text-section">
                <p className="footer-text">
                  ASK ME, IF YOU WANT SPECIAL CONDITIONS FOR YOUR FOOTAGE
                </p>
                <p className="footer-text-sm">LESS OR MORE HOURS ETC</p>
              </div>
              <div className="footer-text-section">
                <p className="footer-text">
                  AND WE WILL FIND <br /> THE BEST SOLUTIONS FOR YOU
                </p>
              </div>
              <div className="footer-text-section">
                <p className="footer-text-sm">
                  THE PRICE INCLUDES 50% DISCOUNT FOR THE POSSIBILITY OF
                  PUBLICATION
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Price;
