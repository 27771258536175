import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { motion } from "framer-motion";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./swiper.css";

const SwiperMobGallery = ({
  imgListSrc,

  fullWidth,
  toggleFullWidth,
}) => {
  let [curSlideIdx, setCurSlideIdx] = useState(1);
  let newImgListSrc = imgListSrc.slice(1, -1);
  if (curSlideIdx.toString().length < 2) curSlideIdx = "0" + curSlideIdx;

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      onSlideChange={(i) => {
        setCurSlideIdx(i.activeIndex + 1);
      }}
    >
      <SwiperSlide>
        <div
          style={{
            backgroundImage: `url(..${imgListSrc[0].src})`,
            height: "100vh",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="touch-icon-mob swipe">SWIPE LEFT FOR MORE</div>
        </div>
      </SwiperSlide>
      {newImgListSrc.map((img, i) => (
        <SwiperSlide key={img.id}>
          <div
            style={{
              backgroundImage: `url(..${img.src})`,
              height: "100vh",
              width: "100%",
              backgroundSize: fullWidth ? "cover" : "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </SwiperSlide>
      ))}
      <span
        className="slider-count"
        initial={{ opacity: 0, translateY: -100 }}
        animate={{ opacity: 1, translateY: 0 }}
      >
        {curSlideIdx}
      </span>
      {curSlideIdx !== "01" && (
        <span className="touch-icon-mob" onClick={toggleFullWidth}>
          {fullWidth ? "FULL FRAME" : "CLOSE UP"}
        </span>
      )}
    </Swiper>
  );
};

export default SwiperMobGallery;
