import React from "react";
import ReactPlayer from "react-player/lazy";
import "./videoPlayer.css";

const VideoPlayer = () => {
  return (
    <div className="playerWrapper">
      <ReactPlayer
        id="player"
        className="player"
        width="100%"
        height="100%"
        controls={false}
        muted
        autoPlay
        playing
        loop
        playsinline
        url="https://vimeo.com/1034487362"
      ></ReactPlayer>
    </div>
  );
};

export default VideoPlayer;
