import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { navigation } from "./config";
import NavItem from "./NavItem";
import whatsapp from "../../img/icons/whatsapp-icon.svg";
import instagram from "../../img/icons/instagram-icon.svg";

const Navigation = ({ open, toggleMenu }) => {
  return (
    <>
      <div className={open ? "menu-mob active" : "menu-mob"}>
        <nav>
          <ul>
            {navigation.map((item, i) => (
              <NavItem
                link={item.link}
                text={item.text}
                imgSrc={item.imgSrc}
                key={item.id}
                toggleMenu={toggleMenu}
                idx={i}
                open={open}
              />
            ))}
          </ul>
        </nav>
        animation-delay: 2s;
        <div className="social">
          <div className="social-in">
            <div className={open ? "link-wrap active" : "link-wrap"}>
              <a
                style={{
                  transitionDelay: open ? "0.1s" : "0s",
                }}
                href="https://wa.me/380674459055"
                target="_blank"
                rel="noreferrer"
              >
                <img src={whatsapp} alt="" />
              </a>
            </div>
            <div className={open ? "link-wrap active" : "link-wrap"}>
              <a
                // style={{
                //   transitionDelay: open ? "0.3s" : "0s",
                // }}
                href="https://www.instagram.com/wedlifer/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="" />
              </a>
            </div>
          </div>
          <div
            className={open ? "nav-privacy-link active" : "nav-privacy-link"}
          >
            <NavLink
              to="/privacy/"
              // style={{
              //   transitionDelay: open ? "0.1s" : "0s",
              // }}
            >
              Privacy policy
            </NavLink>
            <NavLink to="/terms/">Terms & Conditions</NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
